import * as React from 'react';
import { config } from '../config';
import PageTemplate from '../templates/page';
import { LocalFilesType } from '@umg/mira-widgets';
import styled from 'styled-components';
import {
  getUserInfo,
  handlerViewerRequest,
  navigateToErrorPage,
} from '../routers';
import { getRoute } from '@umg/mira-router-utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  BUILDER_CONTENT_ROUTE,
  PUBLIC_API_CONTENT_ROUTE,
  PAGE_PREVIEW_TYPE,
} from '../enums/campaign-build.enums';
import {
  IUserInfo,
  IPageToBuild,
  ICampaign,
} from '../interfaces/campaign-build.interfaces';
import { getMiraContent, getBuilderContent } from '../services/mira-api-legacy';
import { logger, ERROR_TYPES } from '../util/logger';
import DoNotShareAlert from '../components/do-not-share-alert';
import { Router } from '@umg/mira-widgets/lib/interfaces/routes';

dayjs.extend(utc);
dayjs.extend(timezone);

declare global {
  interface Window {
    ssr: boolean;
  }
}

interface PageProps {
  serverData: {
    isPreview: boolean;
    previewPages: IPageToBuild[];
    pageContext: {
      pageId: string;
      data: any;
    };
    data: {
      allBuilderModels: {
        page: {
          content: any;
          localFiles: LocalFilesType[];
        }[];
      };
    };
  };
}

const PageSelectorContainer = styled.div`
  border-bottom: 1px solid lightGray;
  width: 100%;
  display: flex;
  height: auto;
  justify-content: center;
  padding: 14px 0;
`;

const Select = styled.select`
  height: 32px;
  min-width: 100px;
`;

const PageContent = (props: PageProps) => {
  const [selectedPageId, setSelectedPageId] = React.useState<string>('');
  const [pagePreviewLoading, setPagePreviewLoading] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (props?.serverData?.isPreview) {
      const page = props.serverData.previewPages.find(
        (page: IPageToBuild) => page.id === props.serverData.pageContext.pageId
      );
      setSelectedPageId(page?.id);
      setPagePreviewLoading(true);
    }
    if (typeof window !== 'undefined') {
      window.ssr = true;
    }
  }, []);

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const pageId = event.target.value;
    const previewSegment = location.href.includes(PAGE_PREVIEW_TYPE.Draft)
      ? PAGE_PREVIEW_TYPE.Draft
      : PAGE_PREVIEW_TYPE.Published;
    location.href = `${location.origin}/${previewSegment}${pageId}`;
    setSelectedPageId(pageId);
  };

  if (!props?.serverData?.data?.allBuilderModels?.page[0]?.content) {
    return null;
  }

  if (props.serverData.isPreview) {
    return (
      pagePreviewLoading && (
        <>
          <PageSelectorContainer>
            <Select value={selectedPageId} onChange={handleOnChange}>
              {props.serverData.previewPages.map((page: IPageToBuild) => (
                <option value={page.id} key={page.id}>
                  {page.name}
                </option>
              ))}
            </Select>
            <DoNotShareAlert />
          </PageSelectorContainer>
          <PageTemplate {...props?.serverData} />
        </>
      )
    );
  }

  return <PageTemplate {...props?.serverData} />;
};

const getPageBySlug = async ({
  url,
  isDraft,
  userInfo,
  host,
  pageIdHeader,
}: {
  url: string;
  isDraft: boolean;
  userInfo: IUserInfo;
  host: string;
  pageIdHeader: string;
}) => {
  const segments = url.split('/');
  const campaignSlug = segments[0];
  const pageSlug = segments[1];
  //let pageId: string = pageIdHeader;
  let pageId: string = segments[2] || null;
  let campaign: ICampaign | null = null;
  let page: { id: string; data: IPageToBuild } | null = null;

  if(pageId){
    console.log("Page Slug Id has been passed by Lambda", pageId);
  }

  // Get draft page and campaign from Builder CDN
  if (isDraft) {
    const results = await getBuilderContent(
      BUILDER_CONTENT_ROUTE.Page,
      `overrides.page=${campaignSlug}&includeRefs=true`,
      isDraft
    );
    if (results.length > 0) {
      page = results[0];
      campaign = page?.data?.campaign?.value as ICampaign;
      return { page, campaign };
    }
  }

  // Get published campaign from Mira endpoint
  const campaignRecord = await getMiraContent(
    PUBLIC_API_CONTENT_ROUTE.CampaignVersion,
    `?slug=${campaignSlug}&host=${host}`
  );
  if (campaignRecord && campaignRecord?.campaign) {
    const {
      campaign: { id, data, unpublished, version },
    } = campaignRecord;

    if (!version) {
      console.log("There's no campaign version, this is an old s3 campaign.");
      if (data?.router && !pageId) {
        const routerObj =
          typeof data.router === 'string'
            ? JSON.parse(data.router)
            : data.router;

        pageId = getPageIdFromRouter(
          routerObj,
          `/${pageSlug || ''}`,
          userInfo,
          dayjs
        );
      }

      const pageRecord = await getBuilderContent(
        BUILDER_CONTENT_ROUTE.Page,
        `overrides.page=${pageId}&includeRefs=true`,
        isDraft
      );
      if (pageRecord.length > 0) {
        const page = pageRecord[0];
        campaign = page?.data?.campaign?.value;

        return {
          page,
          campaign,
        };
      }

      return null;
    }

    campaign = {
      id,
      data,
      unpublished,
      version,
    };
  }

  // Get page ID from campaign router
  if (campaign && campaign?.data?.router?.routes?.length > 0 && !pageId) {
    pageId = getPageIdFromRouter(
      campaign.data.router,
      `/${pageSlug || ''}`,
      userInfo,
      dayjs
    );
  }

  // Get page from Builder CDN or Mira endpoint
  if (pageId) {
    if (isDraft) {
      const results = await getBuilderContent(
        BUILDER_CONTENT_ROUTE.Page,
        `overrides.page=${pageId}`,
        isDraft
      );

      if (results && results[0]) page = results[0];
    } else {
      const result = await getMiraContent(
        PUBLIC_API_CONTENT_ROUTE.PagesVersion,
        `?id=${pageId}&version=${campaign.version}`
      );
      if (result?.page) page = { id: pageId, data: result.page.data };
    }
  }

  return { page, campaign };
};

const getPageIdFromRouter = (
  router: Router,
  pageSlug: string,
  userInfo: IUserInfo,
  dayjs: any
): string => {
  const pageIdFromRouter = getRoute(router, pageSlug, userInfo, dayjs);

  return pageIdFromRouter !== '404' ? pageIdFromRouter : null;
};

export async function getServerData(context) {
  let url = context.params['*'];
  console.log('original url: ', url);
  url = url.split('?')[0];

  // listen to all http requests
  // exclude webpack, .io, .ico, .json, .txt, .html files
  if (/(webpack|\.io|\.ico|\.json|\.txt|\.html)/.test(url) || !url) {
    return {
      props: {},
    };
  }

  console.log('url: ', url);

  if (!url) {
    return navigateToErrorPage();
  }

  logger('context: ', ERROR_TYPES.Debug, context);

  let isDraft = false;
  let isPreview = false;
  let previewPages: IPageToBuild[] = [];

  if (url.includes(PAGE_PREVIEW_TYPE.Draft)) {
    isPreview = true;
    isDraft = url.includes(PAGE_PREVIEW_TYPE.Draft);
    url = url.replace(`${PAGE_PREVIEW_TYPE.Draft}`, '');
  }

  context.url = `/${url}`;
  context.headers = [...context.headers]
    .map((header) => {
      if (header[0] && header[1]) {
        return {
          [header[0]]: header[1],
        };
      }
      return header;
    })
    .reduce((acc, curr) => {
      return {
        ...acc,
        ...curr,
      };
    }, {});

  const host =
    context.headers['x-forwarded-host'] || context.headers?.host || null;

  logger('context.url', ERROR_TYPES.Debug, context.url);
  logger('headers', ERROR_TYPES.Debug, context.headers);

  const userInfo = getUserInfo(context);
  logger('userInfo:', ERROR_TYPES.Debug, userInfo);

  const pageIdHeader = context.headers['x-page-id'] || null;

  const result = await getPageBySlug({ url, isDraft, userInfo, host, pageIdHeader });

  if (
    !result ||
    !result?.page ||
    !result?.campaign ||
    (result?.campaign?.unpublished && !isDraft)
  ) {
    return navigateToErrorPage();
  }

  // execute handler for viewer request when is not local env, not preview and is not draft
  // ENABLE_VIEWER_REQUEST_HANDLER is not set anywhere so this is never executed for now
  // should we remove this line since seems this condition is never applied?
  if (
    config.ENV !== 'local' &&
    !isPreview &&
    !isDraft &&
    config.ENABLE_VIEWER_REQUEST_HANDLER
  ) {
    // should redirect if page is locked or redirect to custom domain
    const redirectObj = handlerViewerRequest(
      context,
      result.campaign.data.slug,
      result.campaign.data.router
    );

    if (redirectObj) {
      logger('redirectObj: ', ERROR_TYPES.Debug, redirectObj);
      return redirectObj;
    }
  }

  if (isPreview && !isDraft) {
    const pageIds = result.campaign.data.router.routes.map(
      (route: any) => route.default
    );

    if (pageIds.length > 0) {
      const promises = pageIds.map((pageId: string) =>
        getMiraContent(
          PUBLIC_API_CONTENT_ROUTE.PagesVersion,
          `?id=${pageId}&version=${result.campaign.version}`
        )
      );

      previewPages = (await Promise.all(promises)).reduce(
        (acc: IPageToBuild[], currentValue: any) => {
          if (currentValue?.page && result?.campaign) {
            const campaignData = result.campaign.data;
            const campaignSlug = campaignData.slug;
            const campaignRoutes = campaignData.router.routes;

            currentValue.page.slug = `${campaignSlug}${
              campaignRoutes.find(
                (route: any) => route.default === currentValue.page.id
              )?.url || ''
            }`;

            acc.push({
              ...currentValue.page,
            });
          }
          return acc;
        },
        []
      );
    }
  } else if (isPreview) {
    previewPages = await getBuilderContent(
      BUILDER_CONTENT_ROUTE.Page,
      `query.data.campaign.id=${result.campaign.id}&query.published.$ne=archived`,
      isDraft
    );
  }

  return {
    props: {
      isPreview,
      previewPages,
      pageContext: {
        pageId: result.page.id,
      },
      data: {
        allBuilderModels: {
          page: [
            {
              content: {
                id: result.page.id,
                data: {
                  ...result.page.data,
                  campaign: {
                    id: result.campaign.id,
                    value: result.campaign,
                  },
                },
              },
              localFiles: [],
            },
          ],
        },
      },
      children: null,
    },
  };
}

export default PageContent;
